import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Aligner = makeShortcode("Aligner");
const Button = makeShortcode("Button");
const Process = makeShortcode("Process");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-prosocial-value",
      "style": {
        "position": "relative"
      }
    }}>{`👨‍👩‍👧‍👦 Prosocial Value`}<a parentName="h1" {...{
        "href": "#-prosocial-value",
        "aria-label": " prosocial value permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p><em parentName="p">{`“What should young people do with their lives today? Many things, obviously. But the most
daring thing is to create stable communities in which the terrible disease of loneliness can be
cured.”`}</em></p>
    <p>{`— `}<Link to="https://www.youtube.com/watch?v=GOGru_4z1Vc" mdxType="Link">{`Kurt Vonnegut`}</Link>{`, 1974`}</p>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><img parentName="p" {...{
            "src": "/images/mod5-img/lost-garden.png",
            "alt": "The Lost Garden"
          }}></img></p>
        <Aligner center mdxType="Aligner">
          <Button to="https://lostgarden.com/2020/01/11/prosocial-economics-for-game-design/" mdxType="Button">
    Read
          </Button>
        </Aligner>
      </Box>
    </InfoBlock>
    <h3 {...{
      "id": "how-does-this-fit-into-kernel",
      "style": {
        "position": "relative"
      }
    }}>{`How does this fit into Kernel?`}<a parentName="h3" {...{
        "href": "#how-does-this-fit-into-kernel",
        "aria-label": "how does this fit into kernel permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`The incentives which support power are primarily based on narratives and we can influence these
structures by establishing common knowledge and playing the players. We know we can program
incentive structures at the kinds of scale required to influence the behaviour of large groups
of humans; we know how to design these such that they reveal valuable truths with very little
contrivance. Now the question is: how can we best research, understand and model the kinds of
behaviour which will lead to sustainable societies? What happens when the common knowledge we
establish is fundamentally prosocial? In particular, the authors ask what it would mean to design games that include goals like: `}</p>
    <Process mdxType="Process">
      <p><strong parentName="p">{`reducing loneliness`}</strong>{` `}</p>
      <p><strong parentName="p">{`decreasing toxicity`}</strong>{` and `}</p>
      <p>{`boosting a player’s `}<strong parentName="p">{`positive connections`}</strong>{` with others`}</p>
    </Process>
    <h2 {...{
      "id": "brief",
      "style": {
        "position": "relative"
      }
    }}>{`Brief`}<a parentName="h2" {...{
        "href": "#brief",
        "aria-label": "brief permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Loneliness causes stress, depression, reduced lifespan and can lead to short-term thinking
associated with long-term net negative outcomes. Too often, our technologies and the games we
design with them exacerbate, rather than solve this problem. This is not just applicable to
children, but to our whole society. However, problems are always also opportunities:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Combining the known severity of the consequences of loneliness with the connections shown
between games, technology, and loneliness, it becomes clear that this is a pressing issue `}{`[...]`}{`
As the median age of the world population rises, there’s an opportunity as increasing numbers of
older people play games."`}</p>
    </blockquote>
    <p>{`There’s a growing recognition that toxicity in online communities results from weak social design combined with weak enforcement of positive social norms. When we're lonely and stressed, our desire to belong gets expressed in negative ways like trolling for attention or destroying the connections between others we are failing to make or enjoy ourselves. Critically, toxic behaviour isn't necessarily adversarial: badly designed systems `}<em parentName="p">{`actually generate`}</em>{` toxicity.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The broad solution to both these problems is to design systems that `}<strong parentName="p">{`build relationships
between players`}</strong>{`.`}</p>
    </blockquote>
    <h3 {...{
      "id": "the-grand-project",
      "style": {
        "position": "relative"
      }
    }}>{`The Grand Project`}<a parentName="h3" {...{
        "href": "#the-grand-project",
        "aria-label": "the grand project permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Designing to increase social capital requires expertise in at least three distinct domains.
Project Horseshoe wrote about `}<Link to="https://www.projecthorseshoe.com/reports/ph16/ph16r4.htm" mdxType="Link">{`psychology`}</Link>{` in 2016, `}<Link to="https://www.projecthorseshoe.com/reports/featured/ph18r8.htm" mdxType="Link">{`logistics and scale`}</Link>{` in 2018, and this paper focuses on economics. It is by understanding these three fields together that we can begin to design truly interesting, prosocial mechanisms to help us map and navigate `}<a parentName="p" {...{
        "href": "/learn/module-5/the-peoples-narrative/#social-uses"
      }}>{`intersubjective narrative space`}</a>{`.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: In order to reduce loneliness, decrease toxicity, and boost positive connections, the overarching goal of any mechanism we design should be to build what?`}</p>
        <p>{`Relationships.`}</p>
      </Card>
    </Flash>
    <p>{`Many of the key elements required by the psychological and logistical aspects of friendship
formation are systematically undervalued within common economic practices. Game designers,
however, are concerned with the `}<strong parentName="p">{`flow and transformation of resources, value, and incentives
for player behavior`}</strong>{` - that is, the `}<strong parentName="p">{`internal economy`}</strong>{`, rather than strict "micro" or "macro"
formalisms.`}</p>
    <p>{`Though these are toy economies, and have well-defined boundaries with the real world, we can
still build meaningful relationships between virtual objects. These elements originate mostly
in systems theory and include: tokens, sources, pools, `}<Link to="https://vitalik.eth.limo/general/2017/10/17/moe.html" mdxType="Link">{`sinks`}</Link>{` and
transforms, agents like the player, and "black boxes". Together, these elements and agents can
be used to model the entire internal economy, and balance our games with the correct incentive
structures.`}</p>
    <p>{`Mapping game economies to the real world is challenging: the latter has limited resources and
unlimited needs; whereas games are abundant digital universes in which consumption and ownership
can mean different things. Moreover, economics does not account well for computation and
psychology: the common behavioral models of rational, atomic individuals who are the best judges
of their needs and who exist within weak social networks seem deeply flawed. Economists must
rely on measurement via proxy and weak sampling, whereas game developers have a panopticon that
records every possible player interaction.`}</p>
    <p>{`Moreover, most economists assume a price for everything, but this basic assumption distorts
greatly our transactional relationships, it undervalues our long term relationships, and it
over-emphasizes short-term measurable improvements.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Human groups create public goods in the form of unmeasured relationships, social norms and
cultural practices. These public goods are incredibly valuable in terms of individual health and
happiness. Yet they are not readily measured by economic transactions. Economics in general
struggles with public goods, and social public goods are even more invisible.`}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: True or false: everything has a price.`}</p>
        <p>{`False. This attitude undervalues long-term relationship.`}</p>
      </Card>
    </Flash>
    <blockquote>
      <p parentName="blockquote">{`💡 "The practice of economics erases many of the social phenomena we are interested in examining."`}</p>
    </blockquote>
    <p>{`Economics, as a discipline, suffers from a "replication crisis" - many large scale theories are only testable in the world and the experiments meant to measure them inevitably lack power and scope. In this sense, it is less of a science and more a historical kind of map-making that has
increasingly been pulled into politics, rhetoric and ideology. Here's the conundrum: we need to
design and build balanced economic systems, but our tools suck. `}</p>
    <h3 {...{
      "id": "prosocial-value-patterns",
      "style": {
        "position": "relative"
      }
    }}>{`Prosocial value patterns`}<a parentName="h3" {...{
        "href": "#prosocial-value-patterns",
        "aria-label": "prosocial value patterns permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`We can `}<a parentName="p" {...{
        "href": "/learn/module-3/intention/"
      }}>{`begin by being intentional`}</a>{` about both our explicit and
implicit values, and developing an aesthetic which creates a player experience that best
supports our values. Critically, this needs to be `}<em parentName="p">{`measurable`}</em>{` - game design is an engineering
craft. To achieve this, we'll `}<strong parentName="p">{`use economics as a design practice`}</strong>{` rather than a science.`}</p>
    <p>{`There are truths in economic systems: trade creates value (or destroys it if we miss a key
externality); supply and demand generally work, etc. We can use these to adopt an explicitly
prosocial set of values in our games and economies:`}</p>
    <Process mdxType="Process">
      <p><strong parentName="p">{`Friendship`}</strong>{`: The formation and maintenance of healthy, meaningful friendship networks.`}</p>
      <p><strong parentName="p">{`Thriving individuals`}</strong>{`: The facilitation of eudaimonic happiness. Players feel competence,
volition, and relatedness, both for themselves and for their friends.`}</p>
      <p><strong parentName="p">{`Altruism`}</strong>{`: The promotion of activities that involve intrinsically motivated cooperation.`}</p>
      <p><strong parentName="p">{`Positive group norms`}</strong>{`: The spread and enforcement of shared altruistic social norms within
and across groups.`}</p>
      <p><strong parentName="p">{`Shared goals`}</strong>{`: Players work towards those goals via mutual interdependence, and achieve
feelings of purpose and meaningfulness.`}</p>
    </Process>
    <p>{`By being explicit about these values, `}<a parentName="p" {...{
        "href": "/learn/module-2/engineering/"
      }}>{`we can engineer our games and economies`}</a>{` away from individual and group toxicity, and the resulting loneliness they engender.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Responsible social systems design requires an ethical core. Much like medical doctors, we are
operating on human beings. Huge populations of humans, in fact. As ethical practitioners, we
need our own equivalent of the Hippocratic oath. We should do no harm and, if possible, improve
the social health of our players.`}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: Economics suffers from what kind of crisis? `}</p>
        <p>{`Replication.`}</p>
      </Card>
      <Card mdxType="Card">
        <p>{`Prompt: Given this replication crisis, we should use economics not as a science, but as a `}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{` `}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`.`}</p>
        <p>{`Design practice.`}</p>
      </Card>
      <Card mdxType="Card">
        <p>{`Prompt: Responsible economic practice requires what kind of core?`}</p>
        <p>{`Ethical.`}</p>
      </Card>
    </Flash>
    <h4 {...{
      "id": "friendship",
      "style": {
        "position": "relative"
      }
    }}>{`Friendship`}<a parentName="h4" {...{
        "href": "#friendship",
        "aria-label": "friendship permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <p>{`Instead of the rational optimizer model, we can use `}<em parentName="p">{`contextual reciprocity`}</em>{` to map friendship
as a key value for prosocial game design. All friendships, though different, have `}<a parentName="p" {...{
        "href": "/learn/module-0/trust/#further-references"
      }}>{`common factors`}</a>{`: `}<strong parentName="p">{`proximity`}</strong>{`, `}<strong parentName="p">{`repeat encounters`}</strong>{`, `}<strong parentName="p">{`reciprocity`}</strong>{`, `}<strong parentName="p">{`disclosure`}</strong>{`. Successful reciprocity loops increase value and effort over time and, as this process goes on, the more players trust each other.`}</p>
    <p>{`Highly trusted friends form key long-term support networks in times of need. We can accelerate
friendship formation with three keys: `}<strong parentName="p">{`similarity`}</strong>{`, `}<strong parentName="p">{`intensity`}</strong>{`, and `}<strong parentName="p">{`autonomy support`}</strong>{`.
That is, the more similar people feel, the more intense the emotional situations in which they
interact, and the more they encourage each other's choices; the more likely they are to become
lasting friends.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: What three keys help accelerate friendship formation?`}</p>
        <p>{`Similarity, intensity, autonomy support.`}</p>
      </Card>
    </Flash>
    <h4 {...{
      "id": "trust",
      "style": {
        "position": "relative"
      }
    }}>{`Trust`}<a parentName="h4" {...{
        "href": "#trust",
        "aria-label": "trust permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <p>{`As trust in a community increases, support networks flourish. However, it's really difficult to
measure trust, so we need to rely on some proxies: player's `}<strong parentName="p">{`pairwise bonds`}</strong>{`, `}<strong parentName="p">{`active time
spent together`}</strong>{`, `}<strong parentName="p">{`success together in high-trust situations`}</strong>{`, and `}<strong parentName="p">{`time spent talking
positively`}</strong>{`. These can then be tuned by the observant developer team to increase total trust
over time for each pair as well as the rate of trust accumulation, and to create trust metrics for higher-order groups. The paper analyzes the strengths and weaknesses of Steambirds data collection methods:`}</p>
    <blockquote>
      <p parentName="blockquote">{`A mistake we made early on was looking primarily at metrics like retention and monetization.
These don’t tell us much about what motivates players to play. If I were to build the game again,
I would have implemented the togetherness metric for the very first private alpha. Player
`}<strong parentName="p">{`relationships are top-level intrinsic motivators`}</strong>{` and by only measuring them late in the
process, we completely misunderstood the state of the game we were building.`}</p>
    </blockquote>
    <p>{`Of course, the challenge here is that you can't share your trust metrics in the game because if
you show people what you're observing about them, it irrevocably changes their behavior. Sharing
group health though - if vague, delayed and multi-dimensional so that it can't be gamed - can
encourage prosocial behaviour. Trust also differs across contexts, which makes it even more
difficult to measure: at best we need to track multiple trust metrics in multiple contexts.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: What is the top-level intrinsic motivator in game play?`}</p>
        <p>{`Relationships.`}</p>
      </Card>
      <Card mdxType="Card">
        <p>{`Prompt: Name two metrics we can use to measure the trust required in any long-term relationship?`}</p>
        <p>{`Pairwise bonds, active time spent together, success together in high trust situations, time spent talking positively (any two).`}</p>
      </Card>
    </Flash>
    <h4 {...{
      "id": "positive-sum-resources",
      "style": {
        "position": "relative"
      }
    }}>{`Positive sum resources`}<a parentName="h4" {...{
        "href": "#positive-sum-resources",
        "aria-label": "positive sum resources permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <p>{`There are only a few examples of these in the world: `}<a parentName="p" {...{
        "href": "/learn/module-3/time/"
      }}><strong parentName="a">{`time`}</strong></a>{`
and `}<a parentName="p" {...{
        "href": "/learn/module-4/the-garden/"
      }}><strong parentName="a">{`information`}</strong></a>{` being the two most obvious ones. However,
these are a key economic tool for cooperative game play. This area of exploration is connected
to the software theory of `}<a parentName="p" {...{
        "href": "/build/open-data"
      }}>{`agalmics`}</a>{`: non-scarce resources. Whether or not players compete over a resource becomes a design choice,
not a fundamental constraint.`}</p>
    <p>{`We need to look for competition or toxicity and design away any non-zero resources at the heart
of such interactions. You may ask whether this will destroy our incentives to compete, but
cumulative scores are an inherent positive sum resource (everyone gets some independence from other
players), and coordination games are every bit as popular as other kinds.`}</p>
    <p>{`If there are infinite resources, won't we just have imbalanced economies? `}</p>
    <blockquote>
      <p parentName="blockquote">{`It is important to internalize that as a game economy designer, you control the sources, the `}<Link to="https://vitalik.eth.limo/general/2017/10/17/moe.html" mdxType="Link">{`sinks`}</Link>{` and the narrative justification for why the world works as it does. Scarcity as well as abundance are aesthetic choices.`}</p>
    </blockquote>
    <p>{`There are arbitrary per player caps, or per group caps, or transaction costs, or sinks that can
achieve balanced incentive structures. Again, the mapping from games to the real world is not exact:
our total relationship budget is itself zero-sum. We are limited by Dunbar's number, but this is
perhaps where we can consider those higher level trust metrics like inter-group cooperation.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: Name two positive sum resources.`}</p>
        <p><a parentName="p" {...{
            "href": "/learn/module-3/time/#brief"
          }}>{`Time`}</a>{` and information.`}</p>
      </Card>
    </Flash>
    <h4 {...{
      "id": "knowledge-resources",
      "style": {
        "position": "relative"
      }
    }}>{`Knowledge resources`}<a parentName="h4" {...{
        "href": "#knowledge-resources",
        "aria-label": "knowledge resources permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <p>{`Paul Romer has argued that `}<em parentName="p">{`learned transformations`}</em>{` on scarce zero-sum resources result in more
efficient use: wood burning in a stove vs in an open pit can generate and store far more heat. `}</p>
    <blockquote>
      <p parentName="blockquote">{`A prosocial attribute of knowledge is that supply is determined by the number of clever people
you have creating it. Since knowledge is research by clever people, the more clever people we
have playing, the more knowledge we’ll likely gain `}{`[...]`}{` Appropriately designed knowledge good
economics mean more smart players are an advantage, not a threat.`}</p>
    </blockquote>
    <p>{`Some examples of knowledge in game worlds include `}<strong parentName="p">{`technological processes`}</strong>{`, `}<strong parentName="p">{`explorable spaces`}</strong>{`, and `}<strong parentName="p">{`player skills`}</strong>{`. Like most knowledge goods, these skills are teachable and create an economy of attention and narrative based on passing skills efficiently onto other learners.
Streamers showcase their knowledge goods - one of the reasons for their popularity.`}</p>
    <p>{`Of course, virtual skills and knowledge can be passed on in all sorts of ways - as tokens,
"unlocks" etc. - and so the critical part becomes the `}<strong parentName="p">{`transfer mechanism`}</strong>{` you design. The
paper mentions fun variations where an advanced player can only advance further if they manage
to teach a newer player one of these virtual skills. These creates a tit-for-tat reciprocation
loop where both players are getting something they need.`}</p>
    <blockquote>
      <p parentName="blockquote">{`In general, when setting up transfer mechanisms, try to create a natural interdependency
between players. Economic mechanisms that encourage players to seek out and interact with other
players helps facilitate the friendship equation.`}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: Learned transformations help us use zero-sum resources more efficiently. What mechanism must we focus on in our designs to preserve such efficiency gains?`}</p>
        <p>{`The transfer mechanism.`}</p>
      </Card>
    </Flash>
    <h4 {...{
      "id": "voting-resources",
      "style": {
        "position": "relative"
      }
    }}>{`Voting Resources`}<a parentName="h4" {...{
        "href": "#voting-resources",
        "aria-label": "voting resources permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <p>{`Votes are positive sum economic resources! If we reframe them in economic terms, we gain a large
number of tools for manipulating and building novel prosocial voting economies. The paper cites
Beartopia, where "crafting ingredients" take the form of berries which can be gathered once a
minimum of work had been done. `}</p>
    <p>{`For large projects that consume public space, it requires lots of crafting ingredients - i.e.
high levels of coordination - to execute. `}<strong parentName="p">{`This is directly equivalent to storage on Ethereum`}</strong>{`.
Smaller scale, more ephemeral projects require less participation. `}</p>
    <blockquote>
      <p parentName="blockquote">{`We wanted to empower individuals to make local changes, but ensure the system needed the
political power of increasingly larger groups to make more permanent communal changes.`}</p>
    </blockquote>
    <p>{`This is crucial: in a virtual universe, you need not use the same language of voting and
politics to achieve the same end results! We get so caught up in the `}<Link to="https://tinyurl.com/inertia-language" mdxType="Link">{`inertia of our current linguistic paradigms`}</Link>{` that we can't think creatively about how to achieve the desired results without recourse to the same old formalisms. Use games to free yourself.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: True or false: votes can be positive sum economic resources.`}</p>
        <p>{`True.`}</p>
      </Card>
    </Flash>
    <h4 {...{
      "id": "interdependent-roles",
      "style": {
        "position": "relative"
      }
    }}>{`Interdependent roles`}<a parentName="h4" {...{
        "href": "#interdependent-roles",
        "aria-label": "interdependent roles permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <p>{`Division of labor increases efficiency and so is pervasive through social systems design. It
incentivizes both specialization and coordination and is fueled by trust. However, such high
trust requirements - and the reputational and actual costs of failing in high coordination
tasks - tend to intimidate people. Successful games have
a `}<Link to="https://tinyurl.com/ladder-abstraction" mdxType="Link">{`ladder of activities`}</Link>{`,
starting with low trust activities between generalists, before moving into higher trust
coordination games between specialists. `}</p>
    <p><img parentName="p" {...{
        "src": "/images/mod5-img/trust-diagram.png",
        "alt": "Trust Diagram"
      }}></img></p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: High trust environments most often comprise of specialists in roles with minimal overlap, though players still require what to succeed? `}</p>
        <p>{`Other players.`}</p>
      </Card>
    </Flash>
    <h4 {...{
      "id": "shared-vulnerability",
      "style": {
        "position": "relative"
      }
    }}>{`Shared Vulnerability`}<a parentName="h4" {...{
        "href": "#shared-vulnerability",
        "aria-label": "shared vulnerability permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <p>{`Shared struggle acts as social glue and facilitates tight bonds of trust and higher social
cohesion. The paper cites `}<em parentName="p">{`Eve Online`}</em>{` and the guild rituals people created for recruiting new
members. One high functioning guild would recruit players by giving them understandable tasks
and then leading them into a battle where they would all die, together. It was intended as a
bonding exercise, after which all materials were replaced. This creates high retention in groups
through shared memory and an establishment of `}<strong parentName="p">{`interdependence`}</strong>{`, `}<strong parentName="p">{`loyalty`}</strong>{`, and `}<strong parentName="p">{`generosity`}</strong>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The challenge is to calibrate the kind of shared vulnerability and manage a thoughtful
transition to more peaceful forms of gameplay that amount to recovery therapy.`}</p>
    </blockquote>
    <p>{`Moreover, this example is about tribes with in-group/out-group dynamics, and it isn’t clear how
it would translate to a non-zero-sum shared massive environment.`}</p>
    <h4 {...{
      "id": "player-to-player-trade",
      "style": {
        "position": "relative"
      }
    }}>{`Player to player trade`}<a parentName="h4" {...{
        "href": "#player-to-player-trade",
        "aria-label": "player to player trade permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <p>{`Trade allows both parties to benefit and increases the overall value in the game. Trade can
improve human relationships through creating more overall abundance, and by getting individual
people to engage in reciprocal negotiation and relationship-building. However, boiling
everything down to one, auctionable price is not necessarily prosocial. Given that scarcity in
digital environments is a design choice, there are numerous options available to solving this
which the paper discusses that are cut here for the sake of at least some brevity.`}</p>
    <h4 {...{
      "id": "tying-social-metrics-to-business-success",
      "style": {
        "position": "relative"
      }
    }}>{`Tying social metrics to business success`}<a parentName="h4" {...{
        "href": "#tying-social-metrics-to-business-success",
        "aria-label": "tying social metrics to business success permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <p>{`This is the hardest pattern to implement because it takes us back to the problem that "the
practice of economics erases many of the social phenomena we are interested in examining".
Nevertheless, the paper suggests some ways to do this and then warns that implementing them will
very likely lead into the next section.`}</p>
    <h3 {...{
      "id": "dark-patterns",
      "style": {
        "position": "relative"
      }
    }}>{`Dark patterns`}<a parentName="h3" {...{
        "href": "#dark-patterns",
        "aria-label": "dark patterns permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p><em parentName="p">{`"Ignorance does not create ideologically neutral games."`}</em></p>
    <blockquote>
      <p parentName="blockquote">{`Prosocial economics explicitly brings the tools of economics into social system design. And it
promises to be an effective and scalable means of promoting societal values. `}<strong parentName="p">{`This combination
is a honeypot for bad actors`}</strong>{`. There is a future where the basic social technologies we’ve
described in this paper will be used to create systems of immense evil, debasing the very
aspects of friendship that we seek to elevate.`}</p>
    </blockquote>
    <p>{`The paper cites as examples of dark patterns both Facebook's advertising model and China's
social credit system. Ideologically motivated groups can easily adopt these practices to inject
toxic behaviour into social systems, but the antidote to this is not locking the knowledge up:
it's spreading it as far as possible so more of us can more readily identify such dark patterns.`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Optimizing for proxies, rather than the actual social phenomena`}</strong>{`: this can take the form
of viral installs, GDP, or even the "togetherness" metric in Steambirds cited above. It is
potentially mitigated by more holistic metrics, cross-functional teams and player interviews.`}</li>
      <li parentName="ol"><strong parentName="li">{`Over reliance on extrinsic motivators`}</strong>{`: we can "crowd out" complicated, intrinsic
motivators with extrinsic motivators that are much easier to standardize, code and implement.
We can mitigate this by explicitly designing for individual players and their personal journeys,
rather than for aggregates of average players. Importantly, we must also scope metrics correctly:
internal, private, group and public, and think carefully about what we share with whom and the
effect it will have on various behavioral incentive loops.`}</li>
      <li parentName="ol"><strong parentName="li">{`Replacing prosocial with selfish values`}</strong>{`: economists tend towards evil for a variety of
reasons. Tracking and focusing on currency seems to lead to rationalizations that justify
selfish behavior. When we embed extrinsic, currency-based values in
our `}<a parentName="li" {...{
          "href": "/learn/module-4/self-enquiry/"
        }}>{`identity`}</a>{` as individuals and tribes, these just get amplified
at larger social layers and more deeply entrenched, which results in clashes of dogma and
doctrine which are no-win.`}</li>
    </ol>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: How can we act against ideologically motivated dark use of prosocial patterns?`}</p>
        <p>{`Spread knowledge as far as possible.`}</p>
      </Card>
    </Flash>
    <p>{`Let us then re-emphasize the patterns described above:`}</p>
    <Process mdxType="Process">
      <p><strong parentName="p">{`Be explicit about key prosocial values`}</strong></p>
      <p><strong parentName="p">{`Tie economic value to the maintenance of prosocial value`}</strong></p>
      <p><strong parentName="p">{`Don’t put business in control of prosocial systems`}</strong></p>
      <p><strong parentName="p">{`Hire prosocial executives that know the value of these systems`}</strong></p>
      <p><strong parentName="p">{`Establish ethics standards for social systems designers`}</strong></p>
    </Process>
    <h3 {...{
      "id": "weaving-the-threads-together",
      "style": {
        "position": "relative"
      }
    }}>{`Weaving the threads together`}<a parentName="h3" {...{
        "href": "#weaving-the-threads-together",
        "aria-label": "weaving the threads together permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`We can summarize this into three key prosocial mechanics and economic patterns to promote:`}</p>
    <Process mdxType="Process">
      <p><strong parentName="p">{`Honoring the unmeasured`}</strong>{`: trust and positive-sum resources, knowledge in particular.`}</p>
      <p><strong parentName="p">{`Facilitating connection`}</strong>{`: building for friendship formation, encouraging trade and
fostering shared vulnerability.`}</p>
      <p><strong parentName="p">{`Facilitating expression`}</strong>{`: through `}<Link to="https://observablehq.com/@andytudhope/embedded-discover" mdxType="Link">{`voting resources as economic tools`}</Link>{` and integrating social metrics with business success.`}</p>
    </Process>
    <p>{`There are so many areas which you can use this initial exploration to dive into: group leveling;
friendship resources; incentivizing generosity; nurture play; and expressive orthogonality
through fashion. Ideally, we should aim to use the above prosocial design patterns to explore
the space of positive-sum resource design and public good design (see `}<a parentName="p" {...{
        "href": "/learn/module-4/liberal-radical/"
      }}>{`Liberal Radicalism`}</a>{` as one example of such an exploration); to build better cognitive behaviour therapy systems and help manage social skill development
across societies.`}</p>
    <p>{`Of course, a core area of concern is transferring these insights from the game world to the real
one, and this is where cryptocurrencies seem uniquely valuable. Crypto-economic mechanism design
`}<strong parentName="p">{`is all about`}</strong>{` the interface between `}<a parentName="p" {...{
        "href": "/learn/module-1/value/"
      }}>{`value`}</a>{` in a digital and "actual"
sense - it's about how we can use `}<a parentName="p" {...{
        "href": "/learn/module-2/engineering/"
      }}>{`programmable money`}</a>{` to influence
the way societies organize and function. We invite you to apply the principles above to build
prosocial mechanisms that advance the state of our social `}<a parentName="p" {...{
        "href": "/learn/module-4/art/"
      }}>{`arts`}</a>{`.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: Along with measuring the unmeasured, what two activities must we aim to facilitate with prosocial mechanism design?`}</p>
        <p>{`Connection and expression.`}</p>
      </Card>
    </Flash>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      